import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/Clubs/Humanity/HUMANITIESASSOCIATION/IMG_5076.jpg';
// import p2 from 'assests/Photos/Clubs/Humanity/HUMANITIESASSOCIATION/IMG_5087.jpg';
// import p3 from 'assests/Photos/Clubs/Humanity/HUMANITIESASSOCIATION/IMG_5096.jpg';
// import p4 from 'assests/Photos/Clubs/Humanity/HUMANITIESASSOCIATION/IMG_5108.jpg';



import { Typography } from '@mui/material';
import Container from 'components/Container';

import HumanityClub from 'views/HumanityClub';
import Sidebar2023 from 'views/Humanity2023/Sidebar2023';

const HumanityAssociation = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/Humanity/Humanity-assocation/1.jpg`;
  const p2 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/Humanity/Humanity-assocation/2.jpg`;
  const p3 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/Humanity/Humanity-assocation/3.jpg`;
  const p4 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/Humanity/Humanity-assocation/4.jpg`;


  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },


    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      HUMANITIES ASSOCIATION
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 9 & 10  ,  28 Oct 2022
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        Humanities Association organised a competition on ‘Jaago Grahak Jaago’ for the students of
classes 9&10. The competition was based on consumer awareness where participants from four
different houses competed against each other in a bash of color and vividness. The participants
eagerly competed with great enthusiasm, thereby portraying their performing skills on the topics
such as the requirement or implementation of hallmark on products to prove their authenticity,
fake products are cheap, importance of the expiry dates on products and check before you pay. All
these performances were extremely informative and enlightened us on consumer awareness.
Students actively participated and enlivened the event with their enthusiastic spirits.
        </Typography>
      </Box>
      <Typography
          variant={'h5'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
        “BE ALERT AND ENSURE YOUR RIGHT AS A CONSUMER”
<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <HumanityClub />
             </Box>

             <Box marginBottom={4}>
              <Sidebar2023 />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default HumanityAssociation;